import axios from 'axios'
import tarifAdditionallyImg1 from '../../../../Assets/Home/img/Tarifs/image (11).png'
import tarifAdditionallyImg2 from '../../../../Assets/Home/img/Tarifs/image (12).png'
import { urlAdmin } from '../../urlAdminApi/urlAdmin'

export const servicesAdminData = [
    {id: 1, img: tarifAdditionallyImg1, title: 'Ночной безлимит', subTitle: 'с 23:00 до 06:00',  dataText: 'Снимает все ограничения по скорости интернета в ночное время, предоставляя неограниченный доступ к сети для скачивания, стриминга и других задач.', price: '25 TJS в месяц', longText: ''},
    {id: 2, img: tarifAdditionallyImg1, title: 'Ночной безлимит на месяц', subTitle: 'Про ночной безлимит', data: 'Скорость до 234 MBit', price: '254  TJS в мес'},
    {id: 3, img: tarifAdditionallyImg2, title: 'Flash', subTitle: 'усиливай скорость на 24/ч',   dataText: 'Позволяет временно увеличить скорость интернета до 50 Мбит/секунд на 24 часа, обеспечивая быстрый доступ к сети в любой момент.',price: '25 TJS в день', },
    {id: 4, img: tarifAdditionallyImg2, title: 'Flash на месяц', subTitle: 'Про Flash', data: 'Скорость до 234 MB', price: '254  TJS в мес'},
]
export const additionalSpeed = async () => {
    return await axios.get(`${urlAdmin}cust_cab/get_additional_speed`, {headers: {
     'Authorization': localStorage.getItem('access_token')
    }})
 }
export const additionalSpeedAdd = async (id) => {
    return await axios.post(`${urlAdmin}cust_cab/add_additional_speed`, {
        "additional_speed_id": id
    }, {headers: {
     'Authorization': localStorage.getItem('access_token')
    }})
 }
// cust_cab/get_additional_speed