import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const AuthGuard = () => {
  if(!localStorage.getItem('access_token')){

    return <Navigate to={'/login'}/>
  }
  return <Outlet/>;
}

export default AuthGuard
