import React, { useState } from "react";
import "./AdminHomeBalanceCard.scss";
import MyButton from "../../../../UI/MyButton/MyButton";
import { format, parseISO } from "date-fns";
const AdminHomeBalanceCard = ({ myBalance, myTarif }) => {
  const [active, setActive] = useState(false);
  const handleActive = () => {
    setActive(!active);
  };
  const endDate = myBalance?.end_date;

  var formattedDate = "Неизвестно";
  if (endDate) {
    try {
      formattedDate = format(parseISO(endDate), "dd.MM.yyyy");
    } catch (error) {
      console.error("Ошибка при парсинге даты:", error);
    }
  }
  const now = new Date();

  const hours = now.getHours();
  const minutes = now.getMinutes();

  const formattedTime = `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  return (
    <div className="adminHomeBalanceCard_block">
      <div className="adminHomeBalanceCardTop_block">
        <div className="adminHomeBalanceCardTop_item">
          <h2>Баланс:</h2>
          <h3>{myBalance?.balance}</h3>
        </div>
        <MyButton
          name={"+Пополнить"}
          width={"142px"}
          height={"45px"}
          size={"14px"}
          data={handleActive}
        />
      </div>
      <div className="adminHomeBalanceCardTarif_block">
        <h4>{myTarif?.name}</h4>
        <p>Активен до {formattedDate}</p>
      </div>
      <h6>Данные актуальны на сегодня {formattedTime}</h6>
      {active && (
        <div className="adminServicesModuleModal_container">
          <div className="adminServicesModuleModal_block">
            <div className="adminServicesModuleModal_item">
              <div className="adminServicesModuleModal_text">
                <h4>Уважаемый Абонент!</h4>
                <p>
                  Для вашего удобства мы предлагаем несколько простых и быстрых
                  способов пополнения баланса. Вы можете выбрать наиболее
                  подходящий:
                </p>
              </div>
              <div className="adminServicesModuleModal_text">
                <h4>Электронные кошельки</h4>
                <p>
                  Пополните счет через вашу любимую платежную систему. В
                  приложении кошелька выберите «Оплата услуг» →
                  «Интернет-провайдеры» → выберите нас, «NETS». При оплате
                  обязательно укажите ваш лицевой счет.
                </p>
              </div>
              <div className="adminServicesModuleModal_text">
                <h4>Банковский перевод</h4>
                <p>
                Оплата доступна через любой банк. <br /> 
                Наши банковские реквизиты в сомони: <br />  
                •	Получатель: ООО "Нет Солюшенс"<br />
                •	Счет получателя: 20202972000000101676<br />
                •	Банк получателя: ЗАО «Душанбе Сити Банк»<br />
                •	Корр. счет: 20402972318411<br />
                •	БИК: 350101841<br />
                •	ИНН: 510022404<br />
                Не забудьте указать лицевой счет в назначении платежа.

                </p>
              </div>
              <div className="adminServicesModuleModal_text">
                <h4>Наличные деньги в кассах:</h4>
                <p>
                  Вы можете внести оплату в любом нашем филиале. Сообщите
                  оператору ваш лицевой счет для своевременного зачисления
                  средств. Адрес: г. Душанбе, улица Пулоди, 41, 1-й этаж. евой
                  счет в назначении платежа.
                </p>
              </div>
              <div className="adminServicesModuleModal_text">
                <p>
                  Не забывайте: точное указание лицевого счета гарантирует
                  корректное зачисление средств. Номер вашего лицевого счета
                  можно найти в главной домашней страницы данного приложения или
                  на первой странице Абонентского договора. Спасибо за выбор
                  наших услуг! Если у вас остались вопросы, напишите
                  консультанту или звоните по номерам: 4848 или 418004848.
                </p>
              </div>
              <div className="adminServicesModuleModal_btn">

              <MyButton
                name={"OK"}
                width={"120px"}
                height={"40px"}
                data={handleActive}
              />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ); 
};

export default AdminHomeBalanceCard;
