import React from 'react'
import './AdminHeader.scss'
import logo from '../../../Assets/General/Img/logo_nets.svg'
import icon from '../../../Assets/Admin/img/Header/Vector.svg'
import notificationIcon from '../../../Assets/Admin/img/Header/notification-bing.svg'
const AdminHeader = () => {
  return (
    <div className='adminHeader_block'>
      <img src={logo} alt="" className='admin_logo'/>
      <div className="adminHeader_item">
         {/* <div className="adminHeaderIcon_block">
            <img src={icon}alt="" />
         </div> */}
         <div className="adminHeaderNotificationIcon_block">
            <div className="adminHeaderNotificationIcon_circle"></div>
            <img src={notificationIcon} alt="" />
         </div>
      </div>
    </div>
  )
}

export default AdminHeader
