import React, { useEffect, useState } from 'react'
import AdminMenu from '../../../Components/Admin/AdminMenu/AdminMenu'
import { clientData } from './AdminMenuLogic/AdminMenuLogic'
import { handleError } from '../ErrorModule/ErrorModule'
import { useError } from '../../../Components/Admin/ErrorRequest/ErrorProvider'

const AdminMenuModule = () => {
  const { showError } = useError();
  const [clientsData, setClientsData] = useState()
  const getClientData = () => {
    clientData().then(res => {
      setClientsData(res.data)
    }).catch(err => {
      showError(err.message)
    })
  }
  useEffect(()=> { 
    getClientData()
  }, [])
  return (
    <div>
      <AdminMenu clientsData={clientsData}/>
    </div>
  )
}

export default AdminMenuModule
