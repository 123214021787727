import axios from "axios"
import { urlAdmin } from "../../urlAdminApi/urlAdmin"

export const myTarifs = async () => {
   return await axios.get(`${urlAdmin}cust_cab/my_tarif`, {headers: {
    'Authorization': localStorage.getItem('access_token')
   }})
}

export const myBalance = async () => {
   return await axios.get(`${urlAdmin}cust_cab/my_balance`, {headers: {
    'Authorization': localStorage.getItem('access_token')
   }})
}
export const myPakage = async () => {
   return await axios.get(`${urlAdmin}cust_cab/get_my_additional_traffic`, {headers: {
    'Authorization': localStorage.getItem('access_token')
   }})
}
export const myTraffic = async () => {
   return await axios.get(`${urlAdmin}cust_cab/get_my_traffic`, {headers: {
    'Authorization': localStorage.getItem('access_token')
   }})
}