import React from 'react';
import './AdminHomeTrafficsCardBattty.scss';
import img from '../../../../../Assets/Admin/img/Home/∞.svg';
 
const AdminHomeTrafficsCardBattty = ({ myTraffic, limit }) => {
  // Проверяем, что myTraffic — массив, и он не пуст
  const isTrafficAvailable = Array.isArray(myTraffic) && myTraffic.length > 0;
  const isLimitAvailable = limit > 0;
  
  if (!isTrafficAvailable) {
    console.warn('myTraffic пуст или не является массивом:', myTraffic);
  }

  // Если данных нет, показываем 100%
  const limitInGB = limit / 1024 / 1024 / 1024;  
  const trafficUsedInGB = isTrafficAvailable 
    ? myTraffic[0]?.in_bytes / 1024 / 1024 / 1024 
    : 0;

  const resultOneProsent = limit / 100;
  const resultLimitProsent = isTrafficAvailable 
    ? 100 - (myTraffic[0]?.in_bytes / resultOneProsent)
    : 100;  

  return (
    <div className='adminHomeTrafficsCardBattty_block'>
      <div className="adminHomeTrafficsCardBattty_item">
        <div className="adminHomeTrafficsCardBatttyText_block">
          <div className="adminHomeTrafficsCardBatttyText_item">
            <div
              className="adminHomeTrafficsCardBatttyText_line"
              style={{ width: `${resultLimitProsent}%` }}
            ></div>
            <div className="adminHomeTrafficsCardBatttyText">
              { 
                !isLimitAvailable ? (
                  <img src={img} alt="∞" />
                ) : (
                  <>
                    <h2>{trafficUsedInGB.toFixed(1)}GB</h2>
                    <h3>{limitInGB.toFixed(1)}GB</h3>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="adminHomeTrafficsCardBattty_radius"></div>
    </div>
  );
};

export default AdminHomeTrafficsCardBattty;
