import React, { useEffect, useState } from 'react'
import AdminServices from '../../../Components/Admin/AdminServices/AdminServices'
import { additionalSpeed, additionalSpeedAdd } from './AdminServicesLogic/AdminServicesLogic'
import { handleError } from '../ErrorModule/ErrorModule'
import MyButton from '../../../UI/MyButton/MyButton'
import { useError } from '../../../Components/Admin/ErrorRequest/ErrorProvider'

const AdminServicesModule = () => {
  const { showError } = useError();
  const [aditionalSpeed, setAdditionalSpeed] = useState()
  const [active, setActive] = useState(false)
  const getAdditionalSpeed = () => {
    additionalSpeed().then(res => {
          setAdditionalSpeed(res.data)
          console.log(res.data, '5555555')
    }).catch(err => {
      showError(err.message)
    })
  } 
  const postAdditionalSpeedAdd = (id) => {
    additionalSpeedAdd(id).then(res=> {
      setActive(true)
      console.log('fffff')
    }).catch(err=> {
      showError(err.message)
    })
  } 

  useEffect(()=> {
    getAdditionalSpeed()
  }, [])
  return (
    <div>
      
      <AdminServices aditionalSpeed={aditionalSpeed} connect={postAdditionalSpeedAdd} active={active} setActive={setActive}/>
    </div>
  )
}

export default AdminServicesModule
