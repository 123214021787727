import axios from "axios";
import { urlAdmin } from "../urlAdminApi/urlAdmin";

export const handleError = (error, navigate) => {
  switch (error.statusCode) {
    case 400:
      alert("Ошибка на стороне клиента");
      break;
    case 401:
      console.log('aaaaaaa')
      axios.post(`${urlAdmin}refresh`, {
          headers: {
            "Content-Type": "application/json",
            token_type: "bearer",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          console.log("refresh", res);
        })
        .catch((err) => {
          localStorage.clear();
          navigate("/login");
        });
      break;
    case 403:
      alert("У вас нет доступа");
      break;
    case 404:
      navigate("/");
      break;
    case 500:
      alert("Ошибка сервера");
      break;
    case undefined:
      localStorage.clear();
      navigate("/login");
    default:
      console.error(`Unhandled error status code: ${error.statusCode}`, error);
  }
};
