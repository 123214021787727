import axios from "axios"
import { urlAdmin } from "../../urlAdminApi/urlAdmin"

export const adminHistoryList = [
  {id: 1, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 2, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 3, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 4, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 5, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 6, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 7, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 8, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
  {id: 9, title: 'Nets 3', subTitle: 'Оплата тарифа', centerTitle: '12 декабря 2024', balance: '145 TJS'},
]

export const transactionData = async () => {
  return await axios.get(`${urlAdmin}cust_cab/transaction`, {headers: {
   'Authorization': localStorage.getItem('access_token')
  }})
}