import React, { useEffect, useState } from 'react'
import AdminTarifs from '../../../Components/Admin/AdminTarifs/AdminTarifs'
import { homeTerif } from '../../HomeModule/HomeLogic/HomeLogic';
import { addTarifs, tarifsAll } from './AdminTarifsLogic/AdminTarifsLogic';
 
import { useError } from '../../../Components/Admin/ErrorRequest/ErrorProvider';

const AdminTarifsModule = () => {
  const { showError } = useError();
  const [active, setActive] = useState(false) 
  const [adminTarifs, setAdminTarifs] = useState([]);
  const [tarifs, setTarifs] = useState()
  const getAdminarif = () => {
    homeTerif()
      .then((res) => {
        setAdminTarifs(res.data);
      })
      .catch((err) => {
        showError(err.message);
      });
  };
  const getAllTarifs = () => {
    tarifsAll().then(res => {
      console.log(res.data)
      setTarifs(res.data)
    }).catch(err=> {
      showError(err.message)
    })
  }
  const postAddTarif = (id) => {
    addTarifs(id).then(res=> {
      setActive(true)
    }).catch(err=> {
      showError(err.message)
    })
  }

  useEffect(()=> {
    getAdminarif()
    getAllTarifs()
  }, [])
  return (
    <div>
      <AdminTarifs adminTarifs={adminTarifs} tarifs={tarifs} postAddTarif={postAddTarif} active={active}/>
    </div>
  )
}

export default AdminTarifsModule
