import React from "react";
import "./AdminTarifs.scss";
import AdminHeaderModule from "../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule";
import AdminMenuModule from "../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule";
import HomeGeneralSlider from "../../Home/HomeGeneralSlider/HomeGeneralSlider";
import AdminTarifsCard from "./AdminTarifsCard/AdminTarifsCard";
import MyButton from "../../../UI/MyButton/MyButton";
const AdminTarifs = ({adminTarifs, tarifs, postAddTarif, active, setActive}) => {
  const handleActive = () => {
    handleActive(false) 
  }
  return (
    <div className="adminTarifs_container">
      <AdminHeaderModule />
      <div className="adminTarifs_block">
        <AdminMenuModule />
        <div className="adminTarifs_item">
          <HomeGeneralSlider />
          <div className="adminTarifsCards_block">
            <h1>Выберите тариф</h1>
            <div className="adminTarifsCards_item">
              <AdminTarifsCard adminTarifs={adminTarifs} tarifs={tarifs} postAddTarif={postAddTarif}/>
            </div>
          </div>
        </div>
      </div>
      {
      active &&
    <div className='adminServicesModuleModal_container'>
        <div className="adminServicesModuleModal_block">

        <h2>Пакет успешно подключен</h2>
        <MyButton name={'OK'}  width={'120px'}  height={'40px'} data={handleActive}/>
        </div>
      </div>
    }
    </div>
  );
};

export default AdminTarifs;
