import React from 'react'
import './AdminHistoryListItem.scss'
import { format, parseISO } from "date-fns";

const AdminHistoryListItem = ({ title, subTitle, centerTitle, balance }) => {
  var formattedDate = "Неизвестно";
  if (centerTitle) {
    try {
      formattedDate = format(parseISO(centerTitle), "dd.MM.yyyy");
    } catch (error) {
      console.error("Ошибка при парсинге даты:", error);
    }
  }
  return (
    <div className="adminHistoryListItem_block">
    <div className="adminHistoryListNameItem_block">
      <h3>{title}</h3>
      <h2>{balance} TJS</h2>
    </div>
    <div className="adminHistorySubTitleItem_block">
      <div className="adminHistorySubTitleItem_item">
       <div className="adminHistorySubTitleItem_circle"></div>
      <p>{subTitle}</p>
      </div>
      <p>{formattedDate}</p>
    </div>
  </div>
  )
}

export default AdminHistoryListItem
