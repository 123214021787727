import React from "react";
import "./AdminHome.scss";
import AdminMenuModule from "../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule";
import AdminHomeTrafficsCard from "./AdminHomeTrafficsCard/AdminHomeTrafficsCard";
import AdminHeaderModule from "../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule";
import HomeGeneralSlider from "../../Home/HomeGeneralSlider/HomeGeneralSlider";
import AdminHomeBalanceCard from "./AdminHomeBalanceCard/AdminHomeBalanceCard";
import AdminHomeTarrifCard from "./AdminHomeTarrifCard/AdminHomeTarrifCard";
import AdminHomePackages from "./AdminHomePackages/AdminHomePackages";
const AdminHome = ({myTarif, myBalance, myTraffic, myPakages}) => {
  return (
    <div className="adminHome_container">
      <AdminHeaderModule />
      <div className="adminHome_block">
        <AdminMenuModule />
        <div className="adminHome_item">
          <HomeGeneralSlider />
          <div className="adminHomeTopCard_block">
            <div className="adminHomeTopCard_item">
              <AdminHomeBalanceCard  myBalance={myBalance} myTarif={myTarif}/>
            </div>
            <div className="adminHomeTopCard_item">
              <AdminHomeTarrifCard myTarif={myTarif} />
            </div>
          </div>
          <div className="adminHomeTopCardBottom_block">
            <div className="adminHomeTopCardBottom_item">
              <AdminHomeTrafficsCard myTraffic={myTraffic} limit={myTarif?.limit}/>
            </div>
            <div className="adminHomeTopCardBottom_item">
              <AdminHomePackages myPakages={myPakages}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
