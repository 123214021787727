import React, { useEffect, useState } from 'react'
import AdminHistory from '../../../Components/Admin/AdminHistory/AdminHistory'
import { transactionData } from './AdminHistoryLogic/AdminHistoryLogic'
import { handleError } from '../ErrorModule/ErrorModule'
import { useError } from '../../../Components/Admin/ErrorRequest/ErrorProvider'

const AdminHistoryModule = () => {
  const { showError } = useError();
  const [transaction, setTransaction] = useState()

   const getTransactionData = () => {
    transactionData().then(res =>{
      console.log(res.data, 'transaction')
      setTransaction(res.data)
    }).catch(err => {
      showError(err.message) 
    })
   }

  useEffect(()=> {
    getTransactionData()
  }, [])
  return (
    <div>
      <AdminHistory transaction={transaction}/>
    </div>
  )
}

export default AdminHistoryModule
