import React from 'react'
import './AdminHomePackages.scss'
import MyButton from '../../../../UI/MyButton/MyButton'
import AdminHomePackagesCard from './AdminHomePackagesCard/AdminHomePackagesCard'
import { useNavigate } from 'react-router-dom'
const AdminHomePackages = ({myPakages}) => {
  const navigate = useNavigate()
  const nabigateTo = () => {
    navigate('/AdminServices')
  }
  return (
    <div className='adminHomePackages_block'>
      <div className="adminHomePackagesTop_block">
        <h1>Пакеты:</h1>
        <MyButton name={'Посмотреть доступные'} width={'220px'} height={'45px'} size={'14px'} data={nabigateTo}/>
      </div>
      <div className="adminHomePackages_item">
        {
         myPakages?.map((e)=> 
        
          <AdminHomePackagesCard name={e.name} date={e.expire_at} description={e.description}/> 
        )
        }
      </div>
    </div>
  )
}

export default AdminHomePackages
