import React from 'react'
import './AdminMenu.scss'
import AminMenuDataCard from './AminMenuDataCard/AminMenuDataCard'
import AdminMenuLinkCard from './AdminMenuLinkCard/AdminMenuLinkCard'

const AdminMenu = ({clientsData}) => {
  return (
    <div className='AdminMenu_container'>
       <AminMenuDataCard clientsData={clientsData}/>
       <AdminMenuLinkCard/>
    </div>
  )
}

export default AdminMenu
