import React, { createContext, useContext, useState } from 'react';

 
const ErrorContext = createContext();

 
export const useError = () => useContext(ErrorContext);

 
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  
  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(null), 5000);  
  };

  return (
    <ErrorContext.Provider value={{ showError }}>
      {children}
      {error && <ErrorNotification message={error} />}
    </ErrorContext.Provider>
  );
};

 
const ErrorNotification = ({ message }) => (
  <div className="error-notification">
    <p>{message}</p>
  </div>
);

export default ErrorNotification;
