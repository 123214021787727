import React from 'react'
import './AminMenuDataCard.scss'
import AminMenuDataCardText from './AminMenuDataCardText/AminMenuDataCardText'
const AminMenuDataCard = ({clientsData}) => {

  return (
    <div className='aminMenuDataCard_block'>
      <AminMenuDataCardText title={'Имя'} subtitle={clientsData?.fio}/>
      <AminMenuDataCardText title={'Лицевой счет'} subtitle={clientsData?.id}/>
      <AminMenuDataCardText title={'Номер телефона'} subtitle={clientsData?.phone_number}/>
    </div>
  )
}

export default AminMenuDataCard
