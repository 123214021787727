import React, { useEffect, useState } from 'react'
import AdminHome from '../../../Components/Admin/AdminHome/AdminHome'
import {  myBalance, myPakage, myTarifs, myTraffic } from './AdminHomeLogic/AdminHomeLogic'
import { handleError } from '../ErrorModule/ErrorModule'
import { useNavigate } from 'react-router-dom'
import { useError } from '../../../Components/Admin/ErrorRequest/ErrorProvider'

const AdminHomeModule = () => { 
  const { showError } = useError();
  const navigate = useNavigate();
  const [myTarif, setMytarif] = useState();
  const [myBalanse, setBalance] = useState();
  const [myTraffics, setTraffic] = useState();
  const [myPakages, setMyPakages] = useState();

 
  const fetchData = async () => {
    try {
      const [tarifs, balance, traffic, packages] = await Promise.all([
        myTarifs(),
        myBalance(),
        myTraffic(),
        myPakage(),
      ]);

      setMytarif(tarifs.data);
      setBalance(balance.data);
      setTraffic(traffic.data);
      setMyPakages(packages.data);
    } catch (error) {
      showError(error.message); 
      handleError(error, navigate)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <AdminHome myTarif={myTarif} myBalance={myBalanse} myTraffic={myTraffics} myPakages={myPakages} />
    </div>
  )
}

export default AdminHomeModule
