import React from 'react'
import MyButton from '../../../../../UI/MyButton/MyButton'
import './AdminServiceTarifCustomCard.scss'
import tarifAdditionallyImg2 from '../../../../../Assets/Home/img/Tarifs/image (12).png'
const AdminServiceTarifCustomCard = ({name, field_1, price, field_2, connect, id}) => {
    const addPaket = () => {
        connect(id)
    }
  return (
<div className="tarifCustomCard_block">
      <img src={tarifAdditionallyImg2} alt="" />
      <div className="tarifCustomCard_item">
        <div className="tarifCustomCardTop_text">
          <h2>{name}</h2>
          <p>{field_1}</p>
          <p>{field_2}</p>
        </div>
 
        <div className="tarifCustomCardBottom_text">
            <h2>{price} <span>TJS в месяц</span> </h2>
            <div className="tarifCustomCardBottom_btn">
                
                <MyButton name={'Подключить'} width={'50%'} height={'44px'} size={'16px'}  data={addPaket}/>
                <MyButton name={'Подробнее'} width={'50%'} height={'44px'} size={'16px'} grey={true} />
            </div>
        </div>
      </div>
    </div>
  )
}

export default AdminServiceTarifCustomCard
