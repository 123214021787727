import React from 'react'
import './AdminServices.scss'
import AdminHeaderModule from '../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule'
import AdminMenuModule from '../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule'
import HomeGeneralSlider from '../../Home/HomeGeneralSlider/HomeGeneralSlider'
import AdminServicesCard from './AdminServicesCard/AdminServicesCard'
import MyButton from '../../../UI/MyButton/MyButton'
const AdminServices = ({aditionalSpeed, connect, active, setActive}) => {
  const handleActive = () => {
    setActive(false)
  }
  return (
    <div className="adminservices_container">
    <AdminHeaderModule />
    <div className="adminservices_block">
      <AdminMenuModule />
      <div className="adminservices_item">
        <HomeGeneralSlider />
        <div className="adminservicesCards_block">
          <h1>Доступные услуги</h1>
          <div className="adminservicesCards_item">
            <AdminServicesCard  aditionalSpeed={aditionalSpeed} connect={connect} setActive={setActive}/>
          </div>
        </div>
      </div>
    </div>
    {
      active &&
    <div className='adminServicesModuleModal_container'>
        <div className="adminServicesModuleModal_block">

        <h2>Тариф успешно подключен</h2>
        <MyButton name={'OK'}  width={'120px'}  height={'40px'} data={handleActive}/>
        </div>
      </div>
    }
  </div>
  )
}

export default AdminServices
