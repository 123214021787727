import React, { useState } from "react";
import "./AdminHomePackagesCard.scss";
import MyButton from "../../../../../UI/MyButton/MyButton";
import { format, parseISO } from "date-fns";
const AdminHomePackagesCard = ({name, date, description}) => {
  const [active, setActive] = useState(false);
  const handleActive = () => {
    setActive(!active);
  };
 const formattedDate = format(parseISO(date), "dd.MM.yyyy");
  return (
    <div className="adminHomePackagesCard_block">
      <div className="adminHomePackagesCard_item">
        <h3>{name}</h3>
        <p>Активен до {formattedDate}</p>
      </div>
      <MyButton
        name={"Подробнее"}
        height={"37px"}
        width={"100%"}
        grey={true}
        size={"14px"}
        data={handleActive}
      />
       {active && (
        <div className="adminServicesModuleModal_container">
          <div className="adminServicesModuleModal_block">
            <div className="adminServicesModuleModal_item">
              <div className="adminServicesModuleModal_text">
                <h4>Описание пакета</h4>
                <p>
                  {description}
                </p>
              </div>
           
              <div className="adminServicesModuleModal_btn">

              <MyButton
                name={"OK"}
                width={"120px"}
                height={"40px"}
                data={handleActive}
              />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminHomePackagesCard;
