import imgInfo1 from "../../../../Assets/Home/img/Tarifs/Frame 4.png"; 
import imgInfo4 from "../../../../Assets/Home/img/Tarifs/Frame 3.png";
import imgInfo5 from "../../../../Assets/Home/img/Tarifs/Frame 7.png";
import imgInfo6 from "../../../../Assets/Home/img/Tarifs/mdi_gift-outline.png";
import backImg from "../../../../Assets/Home/img/image16.png";
import logo1 from "../../../../Assets/Home/img/Tarifs/mts-universal-card-benefits (2).png";
import logo2 from "../../../../Assets/Home/img/Tarifs/mts-universal-card-benefits (1).png";
import logo3 from "../../../../Assets/Home/img/Tarifs/mts-universal-card-benefits.png"; 
import { urlAdmin } from "../../urlAdminApi/urlAdmin";
import axios from "axios";

export const adminTarifsTitle = [
    { name: "Домашний интернет" },
    { name: "Годовые пакеты" },
  ];

export const tarifAdminYear = [
    {
      id: 1,
      top_img: backImg,
      link: true, 
      top_text: "Рассрочка от Алиф",
      name: "NETS 2 (6 месяцев)",
      title:
        "Домашний интернет для небольших потребностей в потоковом видео и онлайн-играх.",
        tarif_infos: [
        { image: imgInfo1, text: "Бесплатное подключение" },
        { image: imgInfo5, text: "Рассрочка от Алиф" },
        // { image: imgInfo6, text: "Роутер и IP TV в подарок" },
        { image: imgInfo4, text: "до 5 Мбит/с" },
      ],
      indicator_line: "70%",
      connect: {
        text: "Возможность подключить дополнительн`ые услуги",
        img: [{ img: logo1 }, { img: logo2 }, { img: logo3 }],
      },
      pref_price: "640 TJS/6 мес",
      // curr_price: "115 TJS",
      // stock: "Стоимость по акции первые 2 месяца, с 3 месяца — 115 TJS/мес",
      active: true,
    },
    {
      id: 2,
      top_img: backImg,
      link: true, 
      top_text: "Рассрочка от Алиф",
      name: "NETS 3 (6 месяцев)",
      title:
        "Домашний интернет для небольших потребностей в потоковом видео и онлайн-играх.",
        tarif_infos: [
        { image: imgInfo1, text: "Бесплатное подключение" },
        { image: imgInfo5, text: "Рассрочка от Алиф" },
        { image: imgInfo6, text: "Роутер и IP TV в подарок" },
        { image: imgInfo4, text: "до 10 Мбит/с" },
      ],
      indicator_line: "70%",
      connect: {
        text: "Возможность подключить дополнительные услуги",
        img: [{ img: logo1 }, { img: logo2 }, { img: logo3 }],
      },
      pref_price: "1240 TJS/6 мес",
      // curr_price: "115 TJS",
      // stock: "Стоимость по акции первые 2 месяца, с 3 месяца — 115 TJS/мес",
      active: true,
    },
    {
      id: 3,
      top_img: backImg,
      link: true, 
      top_text: "Рассрочка от Алиф",
      name: "NETS 4 (6 месяцев)",
      title:
        "Домашний интернет для небольших потребностей в потоковом видео и онлайн-играх.",
        tarif_infos: [
        { image: imgInfo1, text: "Бесплатное подключение" },
        { image: imgInfo5, text: "Рассрочка от Алиф" },
        { image: imgInfo6, text: "Роутер и IP TV в подарок" },
        { image: imgInfo4, text: "до 20 Мбит/с" },
      ],
      indicator_line: "70%",
      connect: {
        text: "Возможность подключить дополнительные услуги",
        img: [{ img: logo1 }, { img: logo2 }, { img: logo3 }],
      },
      pref_price: "1590 TJS/6 мес",
      // curr_price: "115 TJS",
      // stock: "Стоимость по акции первые 2 месяца, с 3 месяца — 115 TJS/мес",
      active: true,
    },
    {
      id: 4,
      top_img: backImg,
      link: true, 
      top_text: "Рассрочка от Алиф",
      name: "NETS 2 (12 месяцев)",
      title:
        "Домашний интернет для небольших потребностей в потоковом видео и онлайн-играх.",
        tarif_infos: [
        { image: imgInfo1, text: "Бесплатное подключение" },
        { image: imgInfo5, text: "Рассрочка от Алиф" },
        // { image: imgInfo6, text: "Роутер и IP TV в подарок" },
        { image: imgInfo4, text: "до 5 Мбит/с" },
      ],
      indicator_line: "70%",
      connect: {
        text: "Возможность подключить дополнительные услуги",
        img: [{ img: logo1 }, { img: logo2 }, { img: logo3 }],
      },
      pref_price: "1190 TJS/12 мес",
      // curr_price: "115 TJS",
      // stock: "Стоимость по акции первые 2 месяца, с 3 месяца — 1190 TJS/мес",
      active: true,
    },
    {
      id: 5,
      top_img: backImg,
      link: true, 
      top_text: "Рассрочка от Алиф",
      name: "NETS 3 (12 месяцев)",
      title:
        "Домашний интернет для небольших потребностей в потоковом видео и онлайн-играх.",
        tarif_infos: [
        { image: imgInfo1, text: "Бесплатное подключение" },
        { image: imgInfo5, text: "Рассрочка от Алиф" },
        { image: imgInfo6, text: "Роутер и IP TV в подарок" },
        { image: imgInfo4, text: "до 10 Мбит/с" },
      ],
      indicator_line: "70%",
      connect: {
        text: "Возможность подключить дополнительные услуги",
        img: [{ img: logo1 }, { img: logo2 }, { img: logo3 }],
      },
      pref_price: "1990 TJS/12 мес",
      // curr_price: "115 TJS",
      // stock: "Стоимость по акции первые 2 месяца, с 3 месяца — 1990 TJS/мес",
      active: true,
    },
    {
      id: 6,
      top_img: backImg,
      link: true, 
      top_text: "Рассрочка от Алиф",
      name: "NETS 4 (12 месяцев)",
      title:
        "Домашний интернет для небольших потребностей в потоковом видео и онлайн-играх.",
        tarif_infos: [
        { image: imgInfo1, text: "Бесплатное подключение" },
        { image: imgInfo5, text: "Рассрочка от Алиф" },
        { image: imgInfo6, text: "Роутер и IP TV в подарок" },
        { image: imgInfo4, text: "до 20 Мбит/с" },
      ],
      indicator_line: "70%",
      connect: {
        text: "Возможность подключить дополнительные услуги",
        img: [{ img: logo1 }, { img: logo2 }, { img: logo3 }],
      },
      pref_price: "2590 TJS/12 мес",
      // curr_price: "115 TJS",
      // stock: "Стоимость по акции первые 2 месяца, с 3 месяца — 2590 TJS/мес",
      active: true,
    },
    
 
  ];  

  export const tarifsAll = async () => {
    return await axios.get(`${urlAdmin}cust_cab/get_tarifs`, {headers: {
     'Authorization': localStorage.getItem('access_token')
    }})
 }

 export const addTarifs = async (new_tarif_id) => {
  return await axios.get(`${urlAdmin}cust_cab/change_tarif?new_tarif_id=${new_tarif_id}`, {headers: {
   'Authorization': localStorage.getItem('access_token')
  }})
}