import React, { useState } from 'react'
import AdminLogin from '../../../Components/Admin/AdminLogin/AdminLogin' 
import { handleError } from '../ErrorModule/ErrorModule'
import { useNavigate } from 'react-router-dom'
import { loginAdmin } from './AdminLoginLogic/AdminLoginApi'
import { useError } from '../../../Components/Admin/ErrorRequest/ErrorProvider'

const AdminLoginModule = () => {
  const { showError } = useError();
  const [err, setErr] = useState('')
  const navigate = useNavigate()
  const login = (id, password) => {
    loginAdmin(id, password).then(response => {
      localStorage.setItem('access_token', response.data.access_token)
      localStorage.setItem('refresh_token', response.data.refresh_token)
      navigate('/adminHome')
    }).catch(err => {
      showError(err.response.data.Error)
        setErr(err.response.data.Error)
    })
  }
  return (
    <div>
      <AdminLogin login={login} err={err}/>
    </div>
  )
}

export default AdminLoginModule
