import React, { useState, useEffect, useRef } from "react";
import "./Form.scss";
import img from "../../../Assets/Business/arrow.png";
import MyButton from "../../../UI/MyButton/MyButton";
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Form = ({ setShowModal, show, id, endpoint, service_name }) => {
  const [activeFile, setActiveFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showSeccesMessage, setShowSuccessMessage] = useState(false);
  const location = useLocation();

  const addForm = () => {
    axios
      .post(`https://nets.tj/${endpoint}`, {
        name: name,
        phone: phone,
        interested_in: id,
        service_name: service_name,
      })
      .then((res) => {
        setShowSuccessMessage(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formContainerRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleClickOutside = (event) => {
    if (
      formContainerRef.current &&
      !formContainerRef.current.contains(event.target)
    ) {
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="form_container" ref={formContainerRef} id="form">
      <div className="form_block">
        {showSeccesMessage && (
          <div className="succes_block">
            <p>
              Ваше сообщение доставлено. Мы свяжемся с вами в течение 24 часов.
            </p>
          </div>
        )}
        {show && (
          <IoMdClose
            onClick={() => setShowModal(false)}
            className="formClose_icon"
          />
        )}
        {!showSeccesMessage && (
          <div className="form_item">
            <div className="form_title">
              <h3>Заявка</h3>
              <p>Оставьте данные для консультации и подключения</p>
            </div>
            <div className="form_data">
              <div className="formInput_block">
                <div className="formInput_item">
                  <p>Как вас зовут</p>
                  <p>Необязательно</p>
                </div>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {location.pathname !== "/" &&
                location.pathname !== "/cash" &&
                location.pathname !== "/adminHome" &&
                location.pathname !== "/adminHistory" &&
                location.pathname !== "/adminTarifs" &&
                location.pathname !== "/AdminServices" && (
                  <div className="formInput_block">
                    <div className="formInput_item">
                      <p>Данные о юрлице или ИП</p>
                      <p>Необязательно</p>
                    </div>
                    <input type="text" />
                  </div>
                )}
              <div className="formInputPhone_block">
                <div className="formInputPhone_item">
                  <p>Мобильный телефон</p>
                  <input
                    type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                {location.pathname !== "/" &&
                  location.pathname !== "/cash" &&
                  location.pathname !== "/adminHome" &&
                  location.pathname !== "/adminHistory" &&
                  location.pathname !== "/adminTarifs" &&
                  location.pathname !== "/AdminServices" && (
                    <div className="formInputPhone_item">
                      <p>Email для отправки документов</p>
                      <input type="text" />
                    </div>
                  )}
              </div>
              {}
              {location.pathname !== "/" &&
                location.pathname !== "/cash" &&
                location.pathname !== "/adminHome" &&
                location.pathname !== "/adminHistory" &&
                location.pathname !== "/adminTarifs" &&
                location.pathname !== "/AdminServices" && (
                  <>
                    <div
                      className="formArrowFile_block"
                      onClick={() => setActiveFile(!activeFile)}
                    >
                      <h4>Добавить файлы</h4>
                      <img
                        src={img}
                        alt=""
                        style={{ transform: activeFile && "rotate(180deg)" }}
                      />
                    </div>
                    <>
                      {activeFile && (
                        <div className="vacanciesItemFormFile_block">
                          <div className="vacanciesItemFormFile_item">
                            <div className="vacanciesItemFormFile">
                              <input
                                type="file"
                                id="file"
                                onChange={handleFileChange}
                              />
                              {!fileName && (
                                <p>
                                  Выберите файл{" "}
                                  <span>или переместите сюда</span>
                                </p>
                              )}
                              {fileName && <p>Выбран файл: {fileName}</p>}
                            </div>
                          </div>
                          <h5>Форматы файла: DOC, DOCX, PDF. Не более 5 МБ</h5>
                        </div>
                      )}
                    </>
                  </>
                )}
              <div className="formBtn_block">
                <p>
                  Нажимая кнопку «Отправить», вы соглашаетесь c{" "}
                  <span>условиями обработки персональных данных</span>
                </p>
                <div className="formBtn_item" onClick={() => addForm()}>
                  <MyButton
                    width={"240px"}
                    height={"50px"}
                    name={"Оставить заявку"}
                    size={"17px"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
