import React from 'react'
import './AdminHomeTrafficsCard.scss'
import AdminHomeTrafficsCardBattty from './AdminHomeTrafficsCardBattty/AdminHomeTrafficsCardBattty'
const AdminHomeTrafficsCard = ({myTraffic, limit}) => {

  return (
    <div className='adminHomeTrafficsCard_block'>
       <h1>Остаток трафика:</h1>
       <div className="adminHomeTrafficsCard_item">
         <AdminHomeTrafficsCardBattty myTraffic={myTraffic} limit={limit}/>
       </div>
       <h6>Данные обновляются раз в 10 минут</h6>
    </div>
  )
}

export default AdminHomeTrafficsCard
