import React from 'react'
import './AdminTarifsCardItem.scss'
import MyButton from '../../../../../UI/MyButton/MyButton'
import imgInfo1 from "../../../../../Assets/Home/img/Tarifs/Frame 4.png"; 
import imgInfo4 from "../../../../../Assets/Home/img/Tarifs/Frame 3.png";
import imgInfo5 from "../../../../../Assets/Home/img/Tarifs/Frame 7.png";
import imgInfo6 from "../../../../../Assets/Home/img/Tarifs/mdi_gift-outline.png";
import imgInfo7 from "../../../../../Assets/Home/img/Tarifs/mts-universal-card-features → List → Item → icon_card_min.svg.png";
import imgInfo8 from "../../../../../Assets/Home/img/Tarifs/mts-universal-card-features → List → Item → icon_card_tv.svg.png";
import logo1 from "../../../../../Assets/Home/img/Tarifs/mts-universal-card-benefits (2).png";
import logo2 from "../../../../../Assets/Home/img/Tarifs/mts-universal-card-benefits (1).png";
import logo3 from "../../../../../Assets/Home/img/Tarifs/mts-universal-card-benefits.png"; 
const AdminTarifsCardItem = ({tarifCards, postAddTarif}) => {
  const addTarif = () => {
    postAddTarif(tarifCards.id)
  }
  return (
    <div className="tarifCard_block">
      <div className="tarifCardTop_block">
       
       
       
       {
          tarifCards.month_count === 12 &&
         <a href="https://alifshop.tj/category/nets" target="_blank">
             <p>Рассрочка от Алиф</p> 
         </a> 

        // tarifCards.top_text &&
        // <p>{tarifCards.top_text}</p>
       }
       
      </div>
      <div className="tarifCardBottom_block">
        <div className="tarifCardName_block">
          <h3>{tarifCards.name}</h3>
          <p>{tarifCards.comment}</p>
        </div>
        <div className="tarifCardInfo_block">
         
            <div className="tarifCardInfo_item">
              <img src={imgInfo1} alt="" />
              <p>Бесплатное подключение</p>
            </div>
{
            tarifCards.month_count === 12 ? 
            <>
            
            <div className="tarifCardInfo_item">
              <img src={imgInfo5} alt="" />
              <p>Рассрочка от Алиф</p>
            </div>
            <div className="tarifCardInfo_item">
              <img src={imgInfo6} alt="" />
              <p>Роутер и IP TV в подарок</p>
            </div>
            </>
               :
               <>
              {
               tarifCards.limit === 0 &&     
            <div className="tarifCardInfo_item">
              <img src={imgInfo7} alt="" />
              <p>Безлимитный трафик</p>
            </div>
              } 
            <div className="tarifCardInfo_item">
              <img src={imgInfo8} alt="" />
              <p>Локальные ресурсы до 100 Мбит/с</p>
            </div>
               </>

}

            <div className="tarifCardInfo_item">
              <img src={imgInfo4} alt="" />
              <p>до {(tarifCards?.speed / 1024).toFixed(0)} Мбит/с</p>
            </div>
 
        </div>
        <div className="tarifCardloadding_block">
          <div
            className="tarifCardloadding_line"
            style={{ width: tarifCards.indicator_line + '%' }}
          ></div>
        </div>
        <div className="tarifCardConnect_block">
          <div className="tarifCardConnect_item">
            <img src={logo1} alt="" />
            <img src={logo2} alt="" />
            <img src={logo3} alt="" />
          </div>
          <p>Возможность подключить дополнительные услуги</p>
        </div>
        <div className="tarifCardPrice_block">
          <div className="tarifCardPrice_item">
      

   
              <h2>{tarifCards.price} TJS/мес</h2>
       
          
          </div>
          {/* <h5>{tarifCards.stock}</h5> */}
        </div>
        <div className="tarifCard_btn">
        <MyButton name={"Подключить"} width={"140px"} height={"40px"} data={addTarif} />
        </div>
      </div>
    </div>
  )
}

export default AdminTarifsCardItem
