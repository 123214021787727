import React, { useState } from "react";
import "./AdminHistoryList.scss";
import AdminHistoryListItem from "./AdminHistoryListItem/AdminHistoryListItem";

const AdminHistoryList = ({ transaction }) => {
  const [selectedMonth, setSelectedMonth] = useState("");  

   
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  
  const filteredTransactions = transaction?.filter((e) => {
    if (!selectedMonth) return true;  
    const month = new Date(e.date).getMonth();  
    return month === Number(selectedMonth);  
  });

  return (
    <div className="adminHistoryList_block">
      <div className="adminHistoryList_title">
        <h3>
          Расходы <span>за </span>
        </h3>
        <select name="month" id="month" onChange={handleMonthChange}>
     
          <option value="0">Январь</option>
          <option value="1">Февраль</option>
          <option value="2">Март</option>
          <option value="3">Апрель</option>
          <option value="4">Май</option>
          <option value="5">Июнь</option>
          <option value="6">Июль</option>
          <option value="7">Август</option>
          <option value="8">Сентябрь</option>
          <option value="9">Октябрь</option>
          <option value="10">Ноябрь</option>
          <option value="11">Декабрь</option>
        </select>
      </div>
      <div className="adminHistoryList_item">
        {filteredTransactions?.map((e) => (
          <AdminHistoryListItem
            title={e.score}
            subTitle={e.comment}
            centerTitle={e.date}
            balance={e.summ}
            key={e.id}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminHistoryList;
