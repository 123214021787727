import React from "react";
import "./AdminHomeTarrifCard.scss";
import MyButton from "../../../../UI/MyButton/MyButton";
import iconWifi from '../../../../Assets/Home/img/Tarifs/Frame 3.png'
import { useNavigate } from "react-router-dom";
const AdminHomeTarrifCard = ({myTarif}) => {
  const navigate = useNavigate()
  const navigateTo = () => {
    navigate('/adminTarifs')
  }
  return (
    <div className="adminHomeTarrifCard_block">
      <div className="adminHomeTarrifCardTop_block">
        <div className="adminHomeTarrifCardTop_item">
          <h1>Тариф:</h1>
          <h2>{myTarif?.name}</h2>
        </div>
        <MyButton
          name={"сменить тариф"}
          width={"162px"}
          height={"45px"}
          size={"14px"}
          data={navigateTo}
        />
      </div>
      <p>
      {myTarif?.comment}
      </p>
      <div className="adminHomeTarrifCardSpeed_block">
        <div className="adminHomeTarrifCardSpeed_item">
            <img src={iconWifi} alt="" />
            <h3>до {(myTarif?.speed / 1024).toFixed(0)} Мбит/с</h3>
        </div>
            <div className="tarifCardloadding_block">
          <div
            className="tarifCardloadding_line"
            style={{ width:  '60%' }}
          ></div>
        </div>
      </div>
      <div className="adminHomeTarrifCardBalance_text">
        <h6>Ежемесячный платеж:</h6>
        <h2>{myTarif?.price} TJS/мес</h2>
      </div>
    </div>
  );
};

export default AdminHomeTarrifCard;
